

























































import Vue from "vue";
import * as firebase from "firebase/auth";

export default Vue.extend<any, any, any, any>({
  name: "ForgotPassword",
  props: {
    redirectUrl: {
      type: String,
      default: "/",
    },
  },
  data: () => ({
    valid: false,
    email: "",
    emailRules: [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    submitted: false,
  }),
  methods: {
    validateforgotPassword() {
      const valid = (
        this.$refs.form as Element & { validate: () => boolean }
      )?.validate();

      if (valid) {
        this.forgotPassword();
      }
    },
    forgotPassword() {
      this.submitted = true;
      this.$store.dispatch("setLoadingRequest", true);
      firebase
        .sendPasswordResetEmail(firebase.getAuth(), this.email)
        .then(() => {
          this.submitted = false;
          this.$store.dispatch("setLoadingRequest", false);
          this.$store.dispatch("setToast", {
            title: "Success",
            type: "primary",
            text: "Please check your email for a password reset link to continue.",
            timeout: 60000,
          });
          // const path = (this.$route.query.redirectUrl as string) || "/";
          // this.$router.push({
          //   path: "/login",
          //   query: { redirectUrl: path },
          // });
        })
        .catch((error: Error) => {
          this.submitted = false;
          this.$store.dispatch("setLoadingRequest", false);
          let message = error.message;
          if (message.includes("wrong-password")) message = "Wrong password";
          if (message.includes("user-not-found"))
            message = "User with that email address not found";
          if (message.includes("too-many-requests"))
            message = "Too many attempts. Please try later";
          this.$store.dispatch("setToast", {
            title: "Request Failed",
            type: "error",
            text: message,
          });
        });
    },
  },
});
